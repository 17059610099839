<!--Privacy.vue-->
<template>
  <b-container fluid="md" class="my-4">
    <b-card no-body class="rounded-3 p-3">
      <b-card-body>
        <h2 class="text-center">{{ $t('privacyPolicy.title') }}</h2>
        <p class="text-center"><strong>{{ $t('privacyPolicy.lastUpdated') }}:</strong> 2024-08-21</p>
        <h3>1. {{ $t('privacyPolicy.informationCollection.title') }}</h3>
        <p>{{ $t('privacyPolicy.informationCollection.description') }}</p>
        <ul>
          <li>{{ $t('privacyPolicy.informationCollection.ipAddress') }}</li>
          <li>{{ $t('privacyPolicy.informationCollection.email') }}</li>
          <li>{{ $t('privacyPolicy.informationCollection.googleEmail') }}</li>
          <li>{{ $t('privacyPolicy.informationCollection.cookies') }}</li>
        </ul>
        <h3>2. {{ $t('privacyPolicy.useOfInformation.title') }}</h3>
        <p>{{ $t('privacyPolicy.useOfInformation.description') }}</p>
        <h3>3. {{ $t('privacyPolicy.dataProtection.title') }}</h3>
        <p>{{ $t('privacyPolicy.dataProtection.description') }}</p>
        <h3>4. {{ $t('privacyPolicy.dataSharing.title') }}</h3>
        <p>{{ $t('privacyPolicy.dataSharing.description') }}</p>
        <h3>5. {{ $t('privacyPolicy.userRights.title') }}</h3>
        <p>{{ $t('privacyPolicy.userRights.description') }}</p>
        <h3>6. {{ $t('privacyPolicy.cookies.title') }}</h3>
        <p>{{ $t('privacyPolicy.cookies.description') }}</p>
        <h3>7. {{ $t('privacyPolicy.googleLogin.title') }}</h3>
        <p>{{ $t('privacyPolicy.googleLogin.description') }}</p>
        <h3>8. {{ $t('privacyPolicy.changes.title') }}</h3>
        <p>{{ $t('privacyPolicy.changes.description') }}</p>
        <h3>9. {{ $t('privacyPolicy.contact.title') }}</h3>
        <p>
          {{ $t('privacyPolicy.contact.description') }}
          <br>
          {{ $t('privacyPolicy.contact.email') }}: <a :href="'mailto:contact@miniurl.com'">contact@miniurl.com</a>
        </p>
      </b-card-body>
    </b-card>
  </b-container>
</template>